<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Pamatdati
            </h3>
        </template>

        <template v-slot:content>
            <div class="w-full grid grid-cols-12">
                <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                    <ItemText title="IMEI" :text="item.number"/>
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                    <ItemText title="SKU" :text="item.catalog_item.sku"/>
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-5 ">
                    <ItemText title="Nosaukums" :text="item.catalog_item.name"/>
                </div>

                <template v-if="item.return_act">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                        <div class="flex items-center h-full">
                            <Badge text="Atgriezts" className="bg-red-400 text-red-900" />
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </item-card>

    <item-card>
        <template v-slot:title>
            <div class="flex items-center">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                    Iepirkums
                </h3>

                <Button icon="open-in-new" @click="openProcurement" />
            </div>
        </template>

        <template v-slot:content>
            <div class="w-full grid grid-cols-12">

                <div class="col-span-12 sm:col-span-6 lg:col-span-3 ">
                    <ItemText title="Piegādātājs" :text="item.supplier.name"/>
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                    <ItemText title="Datums" :text="item.procurement.procurement_date"/>
                </div>

                <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                    <ItemText title="Cena" :text="item.procurement_price"/>
                </div>

                <template v-if="item.procurement.supplier_uuid">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Piegādātāja PPR nr." :text="item.procurement.supplier_uuid"/>
                    </div>
                </template>
            </div>
        </template>
    </item-card>

    <template v-if="item.credit_note_items">
        <item-card>
            <template v-slot:title>
                <div class="flex items-center">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        Kredītrēķini
                    </h3>
                </div>
            </template>

            <template v-slot:content>
                <div class="w-full grid grid-cols-12" v-for="(creditNoteItem, index) in item.credit_note_items" :key="index">

                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 ">
                        <ItemText title="UUID" >
                            <template v-slot:content>
                                <router-link :to="`/invoices/credit_notes/${creditNoteItem.credit_note.id}`" class="hover:font-semibold">
                                    {{creditNoteItem.credit_note.uuid}}
                                </router-link>
                            </template>
                        </ItemText>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Datums" :text="creditNoteItem.credit_note.date"/>
                    </div>

                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Klients" :text="creditNoteItem.credit_note.order.customer.name"/>
                    </div>

                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Pasūtījums">
                            <template v-slot:content>
                                <router-link :to="`/orders/${creditNoteItem.credit_note.order.id}`" class="hover:font-semibold">
                                    {{creditNoteItem.credit_note.order.uuid}}
                                </router-link>
                            </template>
                        </ItemText>
                    </div>
                </div>
            </template>
        </item-card>
    </template>


    <template v-if="item.return_act">
        <item-card>
            <template v-slot:title>
                <div class="flex items-center">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        Atgriešanas akts
                    </h3>

                    <Button icon="open-in-new" @click="openReturnAct" />
                </div>
            </template>

            <template v-slot:content>
                <div class="w-full grid grid-cols-12">

                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 ">
                        <ItemText title="UUID" :text="item.return_act.uuid"/>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Datums" :text="item.return_act.date"/>
                    </div>
                </div>
            </template>
        </item-card>
    </template>

    <template v-if="item.order">
        <item-card>
            <template v-slot:title>
                <div class="flex items-center">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        Pasūtījums
                    </h3>

                    <Button icon="open-in-new" @click="openOrder" />
                </div>
            </template>

            <template v-slot:content>
                <div class="w-full grid grid-cols-12">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Klients" :text="item.order.customer.name"/>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="Datums" :text="item.order.date"/>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                        <ItemText title="UUID" :text="item.order.uuid"/>
                    </div>
                </div>
            </template>
        </item-card>
    </template>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"

export default {
    name: "CatalogItemUnitDetails",
    components: {
        ItemCard,
        ItemText,
        Badge,
    },
    props: {
        item: {
            type: Object,
            required: true,
        }
    },
    methods: {
        openProcurement() {
            this.$router.push(`/stock/procurements/${this.item.procurement.id}`)
        },
        openReturnAct() {
            this.$router.push(`/stock/procurement_return_acts/${this.item.return_act.id}`)
        },
        openOrder() {
            this.$router.push(`/orders/${this.item.order.id}`)
        }
    }
}
</script>
